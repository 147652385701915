import { environment } from '@accredible-frontend-v2/envs';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as AuthorizedStore from './authorized/authorized.reducer';
import { authorizedFeatureKey } from './authorized/authorized.reducer';

export interface State {
  [authorizedFeatureKey]: AuthorizedStore.AuthorizedState;
}

export const reducers: ActionReducerMap<State> = {
  [authorizedFeatureKey]: AuthorizedStore.reducer,
};

export const metaReducers: MetaReducer<State>[] = environment.type !== 'production' ? [] : [];
