export const environment = {
  "name": "green",
  "prefix": "green.",
  "type": "development",
  "apiUrl": "https://green.api.accredible.com",
  "socketUrl": "https://green.socket.accredible.com",
  "credentialNetUrl": "https://green.credential.net",
  "courseFinderUrl": "https://green.coursefinder.io",
  "accountsUrl": "https://green.accounts.accredible.com",
  "spotlightUrl": "https://green.employment.accredible.com",
  "salesCalculatorUrl": "https://green.calculator.accredible.com",
  "accredishareUrl": "https://green.accredishare.api.accredible.com",
  "stripeKey": "pk_test_cAeVkx5DhTMwelsEudggtgxC",
  "getSocialId": "a0c42bca",
  "bugsnagApiKey": "",
  "theme": "",
  "version": "25.6.19",
  "recaptchaV2SiteKey": "6LcOWiETAAAAADQyIj7-BSOG8KNsnYIsV84jJDfM",
  "recaptchaV3SiteKey": "6LeLojsaAAAAAB8OV2zHmQvFW66cq79i-xJqdu8j",
  "googleFontApiKey": "AIzaSyCgKPyvoy_y1MMvTtTqIex7OIBlVjbYkYE",
  "themeMicroserviceBaseUrl": "https://theme.ms.credential.net",
  "theming": false,
  "themingS3BaseUrl": "",
  "integrationsApiUrl": "https://greenintegrations.api.accredible.com",
  "zoomAppClientId": "ElQ8D1iBQNOdL2GFT7Mj7A",
  "dashboardGainsightTagKey": "AP-EGY1FB9PXTHZ-2-4",
  "recipientGainsightTagKey": "AP-DDKMXRZFO5TE-2-4",
  "spotlightGainsightTagKey": "AP-XETC94EVLZS5-2-4",
  "unlayerProjectId": "236376"
};