import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleBaseThemeHelper } from '@accredible-frontend-v2/utils/base-theme-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';

export const accountsAliases = [
  'localhost',
  '127.0.0.1',
  'accounts.accredible.com',
  'eu.accounts.accredible.com',
  'red.accounts.accredible.com',
  'green.accounts.accredible.com',
  'blue.accounts.accredible.com',
  'sandbox.accounts.accredible.com',
  'v2.accounts.accredible.com',
];

export class ThemeHelper extends AccredibleBaseThemeHelper {
  static getTheme(): string {
    const appDomain = window.location.hostname;
    return accountsAliases.includes(appDomain) || appDomain.startsWith('192.')
      ? environment.theme || 'credential.net'
      : // Check if appDomain (hostname) has a custom theme, else default to credential.net
      accredibleCustomThemesMetadata[appDomain]
      ? appDomain
      : 'credential.net';
  }
}
