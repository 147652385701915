import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import * as AuthorizedActions from './authorized.actions';
import { AuthorizedService } from './authorized.service';

@Injectable()
export class AuthorizedEffects {
  updateUser = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.updateUser),
      exhaustMap((action) =>
        this._authorizedService.updateUser(action.partialUser).pipe(
          map((user) => AuthorizedActions.updateUserSuccess({ user })),
          catchError(({ errors }) => of(AuthorizedActions.updateUserFailure({ errors }))),
        ),
      ),
    ),
  );

  loadProfile = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.loadProfile),
      exhaustMap((action) =>
        this._authorizedService.loadProfile(action.userId).pipe(
          map((profile) => AuthorizedActions.loadProfileSuccess({ profile })),
          catchError((error) => of(AuthorizedActions.loadProfileFailure({ error }))),
        ),
      ),
    ),
  );

  updateProfile = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.updateProfile),
      exhaustMap((action) =>
        this._authorizedService.updateProfile(action.profile, action.userId).pipe(
          map((profile) => AuthorizedActions.updateProfileSuccess({ profile })),
          catchError((error) => of(AuthorizedActions.updateProfileFailure({ error }))),
        ),
      ),
    ),
  );

  loadSpotlightDirectories = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.loadSpotlightDirectories),
      exhaustMap((action) =>
        this._authorizedService.loadSpotlightDirectories(action.userId).pipe(
          map((res) =>
            AuthorizedActions.loadSpotlightDirectoriesSuccess({ spotlightDirectories: res }),
          ),
          catchError((error) => of(AuthorizedActions.loadSpotlightDirectoriesFailure({ error }))),
        ),
      ),
    ),
  );

  updateSpotlightDirectoryUserSetting = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.updateSpotlightDirectoryUserSetting),
      exhaustMap((action) =>
        this._authorizedService
          .updateSpotlightDirectoryUserSetting(
            action.userId,
            action.userSettingsId,
            action.settings,
          )
          .pipe(
            map((spotlightDirectory) =>
              AuthorizedActions.updateSpotlightDirectoryUserSettingSuccess({ spotlightDirectory }),
            ),
            catchError((error) =>
              of(AuthorizedActions.updateSpotlightDirectoryUserSettingFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadLinkedEmails = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.loadLinkedEmails),
      exhaustMap((action) =>
        this._authorizedService.loadLinkedEmails(action.userId).pipe(
          map((linkedEmails) => AuthorizedActions.loadLinkedEmailsSuccess({ linkedEmails })),
          catchError((error) => of(AuthorizedActions.loadLinkedEmailsFailure({ error }))),
        ),
      ),
    ),
  );

  changeDefaultEmail = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.changeDefaultEmail),
      exhaustMap((action) =>
        this._authorizedService.changeDefaultEmail(action.userId, action.emailId).pipe(
          map((linkedEmails) => AuthorizedActions.changeDefaultEmailSuccess({ linkedEmails })),
          catchError((error) => of(AuthorizedActions.changeDefaultEmailFailure({ error }))),
        ),
      ),
    ),
  );

  addLinkedEmail = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.addLinkedEmail),
      exhaustMap((action) =>
        this._authorizedService
          .addLinkedEmail(
            action.userId,
            action.email,
            action.password,
            action.token,
            action.v3Recaptcha,
          )
          .pipe(
            map((success) => AuthorizedActions.addLinkedEmailSuccess({ success })),
            catchError((error) => of(AuthorizedActions.addLinkedEmailFailure({ error }))),
          ),
      ),
    ),
  );

  resendEmailVerification = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.resendEmailVerification),
      exhaustMap((action) =>
        this._authorizedService.resendEmailVerification(action.userId, action.email).pipe(
          map((success) => AuthorizedActions.resendEmailVerificationSuccess({ success })),
          catchError((error) => of(AuthorizedActions.resendEmailVerificationFailure({ error }))),
        ),
      ),
    ),
  );

  cancelEmailVerification = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.cancelEmailVerification),
      exhaustMap((action) =>
        this._authorizedService.cancelEmailVerification(action.userId).pipe(
          map((success) => AuthorizedActions.cancelEmailVerificationSuccess({ success })),
          catchError((error) => of(AuthorizedActions.cancelEmailVerificationFailure({ error }))),
        ),
      ),
    ),
  );

  setPassword = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.setPassword),
      exhaustMap((action) =>
        this._authorizedService.setPassword(action.password).pipe(
          map(() => AuthorizedActions.setPasswordSuccess()),
          catchError((error) => of(AuthorizedActions.setPasswordFailure({ error }))),
        ),
      ),
    ),
  );

  loadSkills = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthorizedActions.loadSkills),
      switchMap((action) =>
        this._authorizedService.loadSkills(action.query).pipe(
          map((skills) => AuthorizedActions.loadSkillsSuccess({ query: action.query, skills })),
          catchError((error) => of(AuthorizedActions.loadSkillsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _authorizedService: AuthorizedService,
  ) {}
}
