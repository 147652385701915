import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SmartResumeLoginService {
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _document = inject(DOCUMENT);
  private readonly _route = inject(ActivatedRoute);

  redirectToCredentialShare(username: string, sessionToken: string): void {
    const language = this._language.getSelectedLanguage().code;

    const urlParams = new URLSearchParams(this._document.location.search);
    const queryParams = this._route.snapshot.queryParams;
    const recipientToken = queryParams.recipient_token || urlParams.get('recipient_token');

    const shareUrl = `${environment.credentialNetUrl}/profile/${username}/share`;

    this._document.location.href = `${shareUrl}?language=${language}&token=${sessionToken}&recipient_token=${recipientToken}`;
  }

  isSmartResume(): boolean {
    const urlParams = new URLSearchParams(this._document.location.search);
    return urlParams.get('app') === 'smartresume' && urlParams.has('recipient_token');
  }
}
