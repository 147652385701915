import { AccredibleRecaptchaService } from '@accredible-frontend-v2/recaptcha';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountsApiService extends AccredibleApiService {
  private readonly _recaptcha = inject(AccredibleRecaptchaService);

  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 400:
        return super._handleError(res, propagate404, mockData);

      case 401:
        // Session expired or token invalid
        this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);

        this.router.navigate(['login']).then();
        break;

      /**
       * TODO(Fred): The comment below is DEPRECATED. With the BE refactor of API responses 422 will no more be just a recaptcha error only.
       *  Instead it will be 422 but with a specific status message.
       *
       * This case is used to handle the reCAPTCHA v3 failure
       * Documentation - https://developers.google.com/recaptcha/docs/v3
       * "reCAPTCHA v3 returns a score (1.0 is very likely a good interaction, 0.0 is very likely a bot)"
       * When we have a score threshold below 0.5 the backend will throw a 422, now as reCAPTCHA v3 infers the
       * score based on browsing activity and user events,we can't reliably ensure that it's not a bot
       * so instead of potentially locking out a user from a valid request we will instead fallback to the
       * reCAPTCHA v2 which will force a user interaction, and allow them to proceed.
       */
      case 422:
        this._recaptcha.onFailureV3();
        return throwError(res.error);

      case 404:
        return super._handleError(res, propagate404, mockData);
    }

    return throwError(() => new Error(res.message));
  }
}
