import { environment } from '@accredible-frontend-v2/envs';
import { ACCREDIBLE_MFA_SESSION_TOKEN_KEY, AccredibleUser } from '@accredible-frontend-v2/models';
import {
  AccountsRedirectionKey,
  AccredibleAccountsRedirectionService,
} from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import {
  GainsightIdentifyPayload,
  identifyGainsightUser,
} from '@accredible-frontend-v2/utils/gainsight-px';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { AccredibleAuthenticationServiceConfig } from './authentication.config';

/**
 * Authentication logic:
 *
 * All sign in endpoints used in this service return a Bearer token
 * in an Authorization header in the response headers and an empty object on the body.
 * The token is then saved on cookies/localStorage using the cookies service.
 * After signing in, we always need to get the user by calling checkSession().
 *
 * Every time we load an app that uses this logic we check if there's any user
 * signed in by checking browser cookies/localStorage using checkLocalSession().
 * If token is present we can proceed and get our user doing the whoami request with checkSession().
 * If token is not present we redirect the user to our Accounts app sign in page.
 *
 */
const API_ACCOUNT_URL = `/v1/accounts`;

@Injectable({
  providedIn: 'root',
})
export class AccredibleAuthenticationApiService extends AccredibleApiService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _userApi: AccredibleUserApiService,
    private readonly _accountsRedirection: AccredibleAccountsRedirectionService,
    private readonly _config: AccredibleAuthenticationServiceConfig,
    private readonly _route: ActivatedRoute,
  ) {
    super();
  }

  getSessionToken(): string {
    return this._getSessionToken();
  }

  appendSessionTokenToUrl(url: string): string {
    return this._appendSessionTokenToUrl(url);
  }

  checkLocalSession(): void {
    // This happens when user logs out in another tab
    if (this._userApi.isUserSignedIn() && !this.getSessionToken()) {
      this._userApi.deleteUser();
    }
  }

  checkSession(): Observable<AccredibleUser> {
    return new Observable((observer) => {
      this._get('/v1/credential-net/users/whoami').subscribe({
        next: ({ body: { user } }) => {
          this._userApi.setUser(user);
          this._gainsightIdentify(user);
          observer.next(user);
          observer.complete();
        },
        error: (err) => {
          this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  login(loginParams: {
    email: string;
    password: string;
    origin: string;
  }): Observable<{ user: AccredibleUser; sessionToken: string }> {
    return new Observable((observer) => {
      this._post(`${API_ACCOUNT_URL}/sign_in`, loginParams).subscribe({
        next: (res) => this._loginSuccess(res, observer),
        error: (err) => this._loginFailure(err, observer),
      });
    });
  }

  mfaLogin(
    currentOTP: string,
    origin: string,
  ): Observable<{ user: AccredibleUser; sessionToken: string }> {
    return new Observable((observer) => {
      this._post(`${API_ACCOUNT_URL}/mfa_sign_in`, {
        current_otp: currentOTP,
        mfa_session_token: this.cookies.get(ACCREDIBLE_MFA_SESSION_TOKEN_KEY),
        origin,
      }).subscribe({
        next: (res) => this._loginSuccess(res, observer),
        error: (err) => this._loginFailure(err, observer),
      });
    });
  }

  loginWithOneTimeToken(token: string): Observable<{ user: AccredibleUser; sessionToken: string }> {
    return new Observable((observer) => {
      this._post(`${API_ACCOUNT_URL}/auth/get_user_detail_from_one_time_token`, {
        token,
      }).subscribe({
        next: (res) => this._loginSuccess(res, observer),
        error: (err) => this._loginFailure(err, observer),
      });
    });
  }

  // Single Sign On
  loginWithSSOToken(
    token: string,
    issuerId?: number,
    credentialIdOrUuid?: number | string,
  ): Observable<{ user: AccredibleUser; sessionToken: string }> {
    // A different request depending on MiriadaX
    if (issuerId) {
      return new Observable((observer) => {
        this._post(`${API_ACCOUNT_URL}/user/login/jwt`, {
          jwt: token,
          issuer_id: issuerId,
          credential_id: credentialIdOrUuid,
        }).subscribe({
          next: (res) => this._loginSuccess(res, observer),
          error: (err) => this._loginFailure(err, observer),
        });
      });
    } else {
      return new Observable((observer) => {
        this._post(`${API_ACCOUNT_URL}/universal/sso`, {
          key: token,
        }).subscribe({
          next: (res) => this._loginSuccess(res, observer),
          error: (err) => this._loginFailure(err, observer),
        });
      });
    }
  }

  evaluatorProfileComplete(res: HttpResponse<any>): Observable<any> {
    return new Observable((observer) => {
      this._loginSuccess(res, observer);
    });
  }

  logout(): Observable<boolean> {
    return new Observable((observer) => {
      const endpoint = this._userApi.getUser().restrict_to_single_issuer
        ? `${API_ACCOUNT_URL}/sign_out_restricted`
        : `${API_ACCOUNT_URL}/sign_out`;

      this._delete(endpoint).subscribe({
        next: () => {
          this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          this.browserStorage.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          this._userApi.logout();
          observer.next(false);
          observer.complete();
        },
        error: () => {
          console.error('Failed to logout');
          observer.error(false);
          observer.complete();
        },
      });
    });
  }

  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 401:
        // Session expired or token invalid
        this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);

        switch (this._config.app) {
          case 'acs':
            this.router.navigate(['login']).then();
            break;

          case 'rp':
            if (
              this._document.location.pathname !== '/auth' &&
              this._document.location.pathname !== '/user/jwt'
            ) {
              const urlParams = new URLSearchParams(this._document.location.search);
              if (urlParams.has('origin')) {
                this._document.location.href = this._accountsRedirection.getLoginUrlCustomOrigin(
                  urlParams.get('origin'),
                );
              } else {
                this._document.location.href = this._accountsRedirection.getLoginUrlHref();
              }
            }
            break;

          case 'sl':
            this.router.navigate(['']).then();
            break;
        }
        break;

      default:
        return super._handleError(res, propagate404, mockData);
    }
  }

  /**
   * This method runs after every type of login (normal or SSO) but also after MFA sign in.
   */
  private _loginSuccess(
    res: HttpResponse<any>,
    observer: Subscriber<{ user: AccredibleUser; sessionToken: string }>,
  ): void {
    // Delete MFA session token because it will not be necessary anymore
    this.cookies.delete(ACCREDIBLE_MFA_SESSION_TOKEN_KEY);

    if (res.body.next_step === 'mfa_sign_in') {
      res.body.next_step = null;
      this._mfaRedirection(res);
      observer.complete();
      return;
    }

    // User logged in, save token in cookies
    const authorization =
      res.headers.get('Accredible-Authorization') ?? res.headers.get('Authorization');

    // TODO(Fred): Delete fallback to res.body.user.session_token if not used anymore
    const sessionToken = authorization
      ? authorization.replace('Bearer ', '')
      : res.body.user.session_token;

    if (sessionToken) {
      this.cookies.set(AccountsRedirectionKey.SESSION_TOKEN_COOKIE, sessionToken);
      this.checkSession().subscribe((user) => {
        observer.next({ user, sessionToken });
        observer.complete();
      });
    } else {
      observer.error(res);
      observer.complete();
    }
  }

  /**
   * We save the MFA session token in the cookies so that the user can refresh the page,
   * and still be able to set the otp code
   */
  private _mfaRedirection(res: HttpResponse<any>): void {
    this.cookies.set(ACCREDIBLE_MFA_SESSION_TOKEN_KEY, res.body.mfa_session_token);

    const queryParams = this._route.snapshot.queryParams;
    this.router.navigate(['mfa-login'], { queryParams });
  }

  private _loginFailure(
    res: HttpResponse<any>,
    observer: Subscriber<{ user: AccredibleUser; sessionToken: string }>,
  ): void {
    this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
    observer.error(res);
    observer.complete();
  }

  private _appendSessionTokenToUrl(url: string): string {
    const sessionToken = this._getSessionToken();

    if (!!sessionToken) {
      const createdUrl = AccredibleUrlHelper.createUrl(url, 'URL with session token');
      createdUrl.searchParams.set('token', sessionToken);

      return createdUrl.toString();
    }
    return url;
  }

  private _gainsightIdentify(user: AccredibleUser): void {
    const { id, name, email, restrict_to_single_issuer } = user;

    identifyGainsightUser(environment.recipientGainsightTagKey, <GainsightIdentifyPayload>{
      user: {
        id,
        firstName: name,
        email: email,
        issuer: restrict_to_single_issuer,
      },
    });
  }
}
