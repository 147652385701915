<div class="version">{{ version }}</div>

<mat-sidenav-container class="no-footer">
  <accredible-cookies-banner></accredible-cookies-banner>

  <!-- [hidden] is used instead of ngIf because the header component is lazy loaded -->
  <header [hidden]="!showHeader">
    <ng-container #headerContainer></ng-container>
  </header>

  <main id="main-content"
    class="accounts-app-root-container"
    tabindex="-1">
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>
