import { AccredibleUser } from '@accredible-frontend-v2/models';
import { RecaptchaRequest } from '@accredible-frontend-v2/recaptcha';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LinkedEmail } from '../../../containers/settings/containers/general-info/components/linked-emails-dialog/models/linked-emails.model';
import { AccountsProfile } from '../../../shared/models/profile.model';
import {
  AccountsSpotlightDirectory,
  AccountsSpotlightDirectoryUserSettings,
} from '../../../shared/models/spotlight-directory.model';
import { AccountsApiService } from '../../../shared/services/acs-api/acs-api.service';

const API_ENDPOINT = '/v1/recipient';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedService extends AccountsApiService {
  updateUser(user: Partial<AccredibleUser>): Observable<AccredibleUser> {
    return this._put(`/v1/accounts/users/${user.id}`, { user }).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock: /profile.json
  loadProfile(userId: number): Observable<AccountsProfile> {
    return this._get(`${API_ENDPOINT}/users/${userId}`).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  updateProfile(profile: AccountsProfile, userId: number): Observable<AccountsProfile> {
    return this._put(`${API_ENDPOINT}/users/${userId}`, {
      user: profile,
    }).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock: /spotlight_directories.json
  loadSpotlightDirectories(userId: number): Observable<AccountsSpotlightDirectory[]> {
    return this._get(`/v1/accounts/users/${userId}/spotlight_directory_user_settings`).pipe(
      map((res) => this._handleResponse(res, 'spotlight_directory_user_settings')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  updateSpotlightDirectoryUserSetting(
    userId: number,
    customAttributeId: number,
    settings: AccountsSpotlightDirectoryUserSettings,
  ): Observable<AccountsSpotlightDirectory> {
    return this._put(
      `/v1/accounts/users/${userId}/spotlight_directory_user_settings/${customAttributeId}`,
      { spotlight_directory_user_setting: settings },
    ).pipe(
      map((res) => this._handleResponse(res, 'spotlight_directory_user_setting')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  // Mock example: `/email_list.json`, true
  loadLinkedEmails(userId: number): Observable<LinkedEmail[]> {
    return this._get(`/v1/accounts/users/${userId}/user_emails`).pipe(
      map((res) => this._handleResponse(res, 'emails')),
      catchError((res) => this._handleError(res)),
    );
  }

  changeDefaultEmail(userId: number, emailId: number): Observable<LinkedEmail[]> {
    return this._put(`/v1/accounts/users/${userId}/user_emails/${emailId}/change_default`, {}).pipe(
      map((res) => this._handleResponse(res, 'emails')),
      catchError((res) => this._handleError(res)),
    );
  }

  addLinkedEmail(
    userId: number,
    email: string,
    password: string,
    token: string,
    v3Recaptcha: boolean,
  ): Observable<boolean> {
    const body = <any>{
      email,
      password,
      [v3Recaptcha ? RecaptchaRequest.V3 : RecaptchaRequest.V2]: token,
    };

    return this._post(`/v1/accounts/users/${userId}/user_emails`, body).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  resendEmailVerification(userId: number, email: string): Observable<boolean> {
    return this._post(`/v1/accounts/users/${userId}/user_emails/resend_verification_email`, {
      email: email,
    }).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  cancelEmailVerification(userId: number): Observable<boolean> {
    return this._put(`/v1/accounts/users/${userId}/user_emails/cancel_verification_email`, {}).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  setPassword(password: string): Observable<boolean> {
    return this._post('/v1/accounts/auth/set_password', { password }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  loadSkills(query = ''): Observable<string[]> {
    const url = `/v1/credential-net/learning_outcomes/search?query=${query}&page_size=20`;
    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'results')),
      catchError((res) => this._handleError(res)),
    );
  }
}
