import { AccredibleCookiesBannerComponentModule } from '@accredible-frontend-v2/components/cookies-banner';
import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleAccountsRedirectionServiceModule } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationServiceModule } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageServiceModule } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesServiceModule } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageServiceModule,
  CUSTOM_DATE_FORMATS,
  CustomDatePickerAdapter,
} from '@accredible-frontend-v2/services/language';
import { AccredibleUserServiceModule } from '@accredible-frontend-v2/services/user';
import { AccredibleToastModule } from '@accredible-frontend-v2/toast';
import { startBugsnag } from '@accredible-frontend-v2/utils/bugsnag-helper';
import { AccredibleGlobalBugsnagErrorHandler } from '@accredible-frontend-v2/utils/global-bugsnag-error-handler';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ThemeHelper } from '../themes/theme.helper';
import { AccountsLoadModule } from './accounts-load.module';
import { AccountsRoutingModule } from './accounts-routing.module';
import { AccountsContainer } from './accounts.container';
import { AuthorizedEffects } from './stores/authorized/authorized.effects';
import { AuthorizedService } from './stores/authorized/authorized.service';
import { metaReducers, reducers } from './stores/reducer';

const CORE_MODULES = [AccountsLoadModule, AccountsRoutingModule];

const CORE_ACCREDIBLE_LIBS_MODULES = [
  AccredibleAccountsRedirectionServiceModule,
  AccredibleBrowserStorageServiceModule,
  AccredibleCookiesServiceModule,
  AccredibleCookiesBannerComponentModule,
  AccredibleUserServiceModule,
  AccredibleAuthenticationServiceModule.forRoot({ app: 'acs' }),
  AccredibleLanguageServiceModule.forRoot(
    accredibleCustomThemesMetadata[ThemeHelper.getTheme()].languages,
  ),
  AccredibleToastModule.forRoot({
    // The toast durations are 10s/0s due to accessibility considerations,
    // some visual accessible users need more time to see the message
    duration: 10000,
    errorDuration: 0,
  }),
];

const CORE_NPM_MODULES = [RecaptchaV3Module];

const MATERIAL_MODULES = [MatSidenavModule];

const CORE_STORE_EFFECTS = [AuthorizedEffects];

const CORE_STORE_SERVICES = [AuthorizedService];

const CUSTOM_MATERIAL_DATE_ADAPTER = [
  { provide: DateAdapter, useClass: CustomDatePickerAdapter },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
];

const PROVIDERS = <Provider[]>[
  ...CORE_STORE_SERVICES,
  ...CUSTOM_MATERIAL_DATE_ADAPTER,

  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptchaV3SiteKey,
  },
];

if (environment.bugsnagApiKey) {
  const bugsnagClient = startBugsnag('Accounts');
  PROVIDERS.push({
    provide: ErrorHandler,
    useFactory() {
      return new AccredibleGlobalBugsnagErrorHandler(bugsnagClient);
    },
  });
}

@NgModule({
  declarations: [AccountsContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    ...CORE_MODULES,
    ...CORE_ACCREDIBLE_LIBS_MODULES,
    ...CORE_NPM_MODULES,
    ...MATERIAL_MODULES,

    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.type !== 'production'
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    EffectsModule.forRoot(CORE_STORE_EFFECTS),
  ],
  providers: PROVIDERS,
  bootstrap: [AccountsContainer],
})
export class AccountsModule {}
