import { AccredibleUser } from '@accredible-frontend-v2/models';
import { createAction, props } from '@ngrx/store';
import { LinkedEmail } from '../../../containers/settings/containers/general-info/components/linked-emails-dialog/models/linked-emails.model';
import { AccountsProfile } from '../../../shared/models/profile.model';
import {
  AccountsSpotlightDirectory,
  AccountsSpotlightDirectoryUserSettings,
} from '../../../shared/models/spotlight-directory.model';

export const setUser = createAction('[Authorized] Set User', props<{ user: AccredibleUser }>());

export const updateUser = createAction(
  '[Authorized] Update User',
  props<{ partialUser: Partial<AccredibleUser> }>(),
);
export const updateUserSuccess = createAction(
  '[Authorized] Update User Success',
  props<{ user: AccredibleUser }>(),
);
export const updateUserFailure = createAction(
  '[Authorized] Update User Failure',
  props<{ errors: any }>(),
);

export const loadProfile = createAction('[Authorized] Load Profile', props<{ userId: number }>());
export const loadProfileSuccess = createAction(
  '[Authorized] Load Profile Success',
  props<{ profile: AccountsProfile }>(),
);
export const loadProfileFailure = createAction(
  '[Authorized] Load Profile Failure',
  props<{ error: any }>(),
);

export const updateProfile = createAction(
  '[Authorized] Update Profile',
  props<{ profile: AccountsProfile; userId: number }>(),
);
export const updateProfileSuccess = createAction(
  '[Authorized] Update Profile Success',
  props<{ profile: AccountsProfile }>(),
);
export const updateProfileFailure = createAction(
  '[Authorized] Update Profile Failure',
  props<{ error: any }>(),
);

export const loadSpotlightDirectories = createAction(
  '[Authorized] Load Spotlight Directories',
  props<{ userId: number }>(),
);
export const loadSpotlightDirectoriesSuccess = createAction(
  '[Authorized] Load Spotlight Directories Success',
  props<{ spotlightDirectories: AccountsSpotlightDirectory[] }>(),
);
export const loadSpotlightDirectoriesFailure = createAction(
  '[Authorized] Load Spotlight Directories Failure',
  props<{ error: any }>(),
);

export const updateSpotlightDirectoryUserSetting = createAction(
  '[Authorized] Update Spotlight Directory User Settings',
  props<{
    userId: number;
    userSettingsId: number;
    settings: AccountsSpotlightDirectoryUserSettings;
  }>(),
);
export const updateSpotlightDirectoryUserSettingSuccess = createAction(
  '[Authorized] Update Spotlight Directory User Settings Success',
  props<{ spotlightDirectory: AccountsSpotlightDirectory }>(),
);
export const updateSpotlightDirectoryUserSettingFailure = createAction(
  '[Authorized] Update Spotlight Directory User Settings Failure',
  props<{ error: any }>(),
);

export const loadLinkedEmails = createAction(
  '[Authorized] Load Linked Emails',
  props<{ userId: number }>(),
);
export const loadLinkedEmailsSuccess = createAction(
  '[Authorized] Load Linked Emails Success',
  props<{ linkedEmails: LinkedEmail[] }>(),
);
export const loadLinkedEmailsFailure = createAction(
  '[Authorized] Load Linked Emails Failure',
  props<{ error: any }>(),
);

export const changeDefaultEmail = createAction(
  '[Authorized] Change Default Email',
  props<{ userId: number; emailId: number }>(),
);
export const changeDefaultEmailSuccess = createAction(
  '[Authorized] Change Default Email Success',
  props<{ linkedEmails: LinkedEmail[] }>(),
);
export const changeDefaultEmailFailure = createAction(
  '[Authorized] Change Default Email Failure',
  props<{ error: any }>(),
);

export const addLinkedEmail = createAction(
  '[Authorized] Add Linked Email',
  props<{ userId: number; email: string; password: string; token: string; v3Recaptcha: boolean }>(),
);
export const addLinkedEmailSuccess = createAction(
  '[Authorized] Add Linked Email Success',
  props<{ success: boolean }>(),
);
export const addLinkedEmailFailure = createAction(
  '[Authorized] Add Linked Email Failure',
  props<{ error: any }>(),
);

export const resendEmailVerification = createAction(
  '[Authorized] Resend Email Verification',
  props<{ userId: number; email: string }>(),
);
export const resendEmailVerificationSuccess = createAction(
  '[Authorized] Resend Email Verification Success',
  props<{ success: boolean }>(),
);
export const resendEmailVerificationFailure = createAction(
  '[Authorized] Resend Email Verification Failure',
  props<{ error: any }>(),
);

export const cancelEmailVerification = createAction(
  '[Authorized] Cancel Email Verification',
  props<{ userId: number }>(),
);
export const cancelEmailVerificationSuccess = createAction(
  '[Authorized] Cancel Email Verification Success',
  props<{ success: boolean }>(),
);
export const cancelEmailVerificationFailure = createAction(
  '[Authorized] Cancel Email Verification Failure',
  props<{ error: any }>(),
);

export const setPassword = createAction('[Authorized] Set Password', props<{ password: string }>());
export const setPasswordSuccess = createAction('[Authorized] Set Password Success');
export const setPasswordFailure = createAction(
  '[Authorized] Set Password Failure',
  props<{ error: any }>(),
);

// SKILLS
export const loadSkills = createAction('[Authorized] Load Skills', props<{ query?: string }>());
export const loadSkillsSuccess = createAction(
  '[Authorized] Load Skills Success',
  props<{ query: string; skills: string[] }>(),
);
export const loadSkillsFailure = createAction(
  '[Authorized] Load Skills Failure',
  props<{ error: any }>(),
);

// RESET
export const resetValue = createAction('[Authorized] Reset Value', props<{ name: string }>());
export const resetState = createAction('[Authorized] Reset');
