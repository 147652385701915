import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AccountsLoadService } from './accounts-load.service';

export function initApp(appLoadService: AccountsLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.initializeApp();
}

export function validateSessionToken(appLoadService: AccountsLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.validateSessionToken();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AccountsLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AccountsLoadService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: validateSessionToken,
      deps: [AccountsLoadService],
      multi: true,
    },
  ],
})
export class AccountsLoadModule {}
