import { rotateAnimation } from '@accredible-frontend-v2/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';

export type ButtonSize = 'small' | 'normal' | 'large';
export type ButtonColor = 'primary' | 'secondary' | 'natural' | 'success' | 'warning' | 'danger';
export type ButtonType = 'solid' | 'outline' | 'flat' | 'icon';
export type IconPosition = 'left' | 'right';

@Component({
  selector: `button[accredible-button], button[accredible-button-flat], button[accredible-button-outline], button[accredible-button-icon],
    a[accredible-button], a[accredible-button-flat], a[accredible-button-outline], a[accredible-button-icon]`,
  templateUrl: './button.component.html',
  styleUrls: [
    './button.component.scss',
    './button-solid.component.scss',
    './button-outline.component.scss',
    './button-flat.component.scss',
    './button-icon.component.scss',
  ],
  animations: [rotateAnimation],
  // ViewEncapsulation.None in order to be able to target the HTML tags <a> and <button> specifically
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleButtonComponent {
  @Input()
  size: ButtonSize = 'normal';
  @Input()
  color: ButtonColor = 'primary';
  @Input()
  icon: string;
  @Input()
  iconSize: string;
  @Input()
  iconPosition: IconPosition = 'right';
  @Input()
  rotateIcon = false;
  @Input()
  isLoading = false;
  @Input()
  hideText = false;

  @HostBinding('class')
  get classes(): string {
    return [this.size, this.color].join(' ');
  }

  // FIXME(Manuel) ariaExpanded state is not handled correctly in onPush components, this will be fixed in angular 15.
  // Remove this hostListener when angular is updated
  @HostListener('cdkMenuOpened')
  @HostListener('cdkMenuClosed')
  setAriaExpanded(): void {
    this.el.nativeElement.ariaExpanded =
      this.el.nativeElement.ariaExpanded === 'true' ? 'false' : 'true';
  }

  // ElementRef is not private because is used to calculate rotateIcon using the ariaExpanded property of the nativeElement
  constructor(readonly el: ElementRef) {}
}
