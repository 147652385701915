import { environment } from '@accredible-frontend-v2/envs';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageService,
  getAvailableLanguages,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { addGainsightPXCode } from '@accredible-frontend-v2/utils/gainsight-px';
import { addGoogleTagManager } from '@accredible-frontend-v2/utils/google-tag-manager-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SmartResumeLoginService } from '../shared/external/smart-resume/smartresume-login.service';
import { ThemeHelper } from '../themes/theme.helper';
import * as AuthorizedActions from './stores/authorized/authorized.actions';
import { AuthorizedState } from './stores/authorized/authorized.reducer';

@Injectable()
export class AccountsLoadService {
  private readonly _themeName = ThemeHelper.getTheme();
  private readonly _document = inject(DOCUMENT);
  private readonly _authorizedStore = inject(Store<AuthorizedState>);
  private readonly _authenticationApi = inject(AccredibleAuthenticationApiService);
  private readonly _router = inject(Router);
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _browserStorage = inject(AccredibleBrowserStorageService);
  private readonly _cookies = inject(AccredibleCookiesService);
  private readonly _domSanitizer = inject(DomSanitizer);
  private readonly _matIconRegistry = inject(MatIconRegistry);
  private readonly _smartResume = inject(SmartResumeLoginService);
  private readonly _recipientFeatureFlags = inject(AccredibleRecipientFeatureFlagsService);

  initializeApp(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // We can do pre-app initialization here
      this._setLanguages();
      this._registerAccredibleSvgIcons();
      this._saveRecipientPortalUrlInBrowserStorage();

      addGoogleTagManager();
      addGainsightPXCode(environment.recipientGainsightTagKey);

      // Load feature flags in app init
      this._recipientFeatureFlags.getFeatureFlags().subscribe();

      ThemeHelper.setThemeFavicon(this._themeName);
      ThemeHelper.loadThemeStyles(this._themeName)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.error('Theme styles failed to load');
          resolve(false);
        });
    });
  }

  validateSessionToken(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Unless redirecting from SmartResume, always delete session_token if user clicked Sign in on an associated Accredible app
      // This is done to prevent Accounts from being out of sync with associated Accredible apps (e.g. SSO logins)
      if (this._document.location.pathname === '/login' && !this._smartResume.isSmartResume()) {
        this._cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
      }

      const urlParams = new URLSearchParams(this._document.location.search);
      if (urlParams.has(AccountsRedirectionKey.SESSION_TOKEN_QUERY_PARAM)) {
        // Save token and login the user
        this._cookies.set(
          AccountsRedirectionKey.SESSION_TOKEN_COOKIE,
          urlParams.get(AccountsRedirectionKey.SESSION_TOKEN_QUERY_PARAM),
        );
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
        WindowHelper.removeQueryParamFromUrl(
          AccountsRedirectionKey.SESSION_TOKEN_QUERY_PARAM,
          urlParams,
        );
      } else if (urlParams.has(AccountsRedirectionKey.SESSION_TOKEN_ONE_TIME)) {
        // Login user with one time token
        this._loginWithOneTimeToken(
          urlParams.get(AccountsRedirectionKey.SESSION_TOKEN_ONE_TIME),
        ).then((isLoggedIn) => resolve(isLoggedIn));
        WindowHelper.removeQueryParamFromUrl(
          AccountsRedirectionKey.SESSION_TOKEN_ONE_TIME,
          urlParams,
        );
      } else if (this._cookies.check(AccountsRedirectionKey.SESSION_TOKEN_COOKIE)) {
        // If session token exists, user is logged in
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
      } else {
        // No user is logged in
        resolve(false);
      }
    });
  }

  /**
   * Save the correct Recipient Portal URL in browser storage
   *
   * - If the user came from Recipient Portal we use the 'host' on search params or use the environment.credentialNetUrl
   * - If the user came from Spotlight we find the matching Recipient Portal URL or use the environment.credentialNetUrl
   * - If the user came from CourseFinder or came directly to Accounts we check if there is a recipientPortalUrl saved on browser storage,
   *    if there is, and it isn't 'null', we do nothing because we want the user to go back to the last recipientPortalUrl that he visited,
   *    else we use environment.credentialNetUrl
   */
  private _saveRecipientPortalUrlInBrowserStorage(): void {
    const urlParams = new URLSearchParams(this._document.location.search);
    const app = urlParams.get(AccountsRedirectionKey.APP);
    const host = urlParams.get(AccountsRedirectionKey.HOST);

    switch (app) {
      case 'spotlight':
        this._browserStorage.set(
          AccountsRedirectionKey.RECIPIENT_PORTAL_URL,
          accredibleCustomThemesMetadata[host]
            ? accredibleCustomThemesMetadata[host].recipientPortalUrls[0] ||
                environment.credentialNetUrl
            : environment.credentialNetUrl,
        );
        break;

      case 'recipient-portal':
        if (host) {
          this._browserStorage.set(AccountsRedirectionKey.RECIPIENT_PORTAL_URL, host);
        } else {
          this._browserStorage.set(
            AccountsRedirectionKey.RECIPIENT_PORTAL_URL,
            environment.credentialNetUrl,
          );
        }
        break;
    }

    if (
      !this._browserStorage.get(AccountsRedirectionKey.RECIPIENT_PORTAL_URL) ||
      (this._browserStorage.get(AccountsRedirectionKey.RECIPIENT_PORTAL_URL) &&
        this._browserStorage.get(AccountsRedirectionKey.RECIPIENT_PORTAL_URL) === 'null')
    ) {
      this._browserStorage.set(
        AccountsRedirectionKey.RECIPIENT_PORTAL_URL,
        environment.credentialNetUrl,
      );
    }
  }

  private _setLanguages(): void {
    // Register supported languages
    const supportedLanguages = getAvailableLanguages(
      accredibleCustomThemesMetadata[this._themeName].languages,
    );
    registerSupportedLocalesData(supportedLanguages);

    // Set active language
    const urlParams = new URLSearchParams(this._document.location.search);
    const languageCode = urlParams.get(AccountsRedirectionKey.LANGUAGE);
    this._language.setLanguage(languageCode, this._themeName);
    if (languageCode) {
      this._browserStorage.set(AccountsRedirectionKey.LANGUAGE, languageCode);
    }
  }

  private _registerAccredibleSvgIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'user',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/user.svg'),
    );
  }

  private _checkSession(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Test if token has not expired
      this._authenticationApi.checkSession().subscribe({
        // Success: User is logged in
        next: (user) => {
          // Wait, is this user a restricted issuer?
          if (user.restrict_to_single_issuer) {
            // User logged in as issuer, so he should not be able to access accounts, redirect to login
            this._cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
            this._router.navigate(['']).then();
            resolve(false);
          } else {
            // Save user in authorized store
            this._authorizedStore.dispatch(AuthorizedActions.setUser({ user }));

            const urlParams = new URLSearchParams(this._document.location.search);
            if (urlParams.has(AccountsRedirectionKey.ORIGIN)) {
              // TODO(Fred): If the user clicks forgot password on credential.net sitemap page for example this code runs
              //  should we instead always sign out the user if he goes into non-authenticated routes same way we do with 'login' route on line 66?
              // Redirect to {origin}
              this._document.location.href = `${urlParams.get(
                AccountsRedirectionKey.ORIGIN,
              )}?token=${this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE)}`;
            } else {
              // Go to settings
              resolve(true);
            }
          }
        },
        // Error (401): The session token has expired or the user logged out in other app
        // Our ApiService will delete our session_token from cookies and redirect the user to the login page
        error: () => resolve(false),
      });
    });
  }

  private _loginWithOneTimeToken(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Attempt to log in user with one-time token
      this._authenticationApi.loginWithOneTimeToken(token).subscribe({
        // Success: User is logged in
        next: ({ user }) => {
          // Save user in authorized store
          this._authorizedStore.dispatch(AuthorizedActions.setUser({ user }));
          resolve(true);
        },
        // Error (401): One-time token is invalid
        error: () => {
          // Clear any pre-existing login
          this._cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          resolve(false);
        },
      });
    });
  }
}
