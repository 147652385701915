import {
  AccredibleProfileLocation,
  AccredibleUser,
  CustomAttributeType,
} from '@accredible-frontend-v2/models';
import { Action, createReducer, on } from '@ngrx/store';
import { LinkedEmail } from '../../../containers/settings/containers/general-info/components/linked-emails-dialog/models/linked-emails.model';
import { AccountsProfile } from '../../../shared/models/profile.model';
import { AccountsSpotlightDirectory } from '../../../shared/models/spotlight-directory.model';
import * as AuthorizedActions from './authorized.actions';

export const authorizedFeatureKey = 'authorized';

export interface AuthorizedState {
  user: AccredibleUser;
  profile: AccountsProfile;
  spotlightDirectories: AccountsSpotlightDirectory[];
  linkedEmails: LinkedEmail[];
  skills: string[];

  action: AuthorizedStateAction;
  payload: any;
  error: unknown;
}

export enum AuthorizedStateAction {
  NO_ACTION,
  USER_SAVED,
  SAVE_USER_FAILED,
  PROFILE_LOADED,
  PROFILE_SAVED,
  SPOTLIGHT_DIRECTORIES_LOADED,
  SPOTLIGHT_DIRECTORY_UPDATED,
  SPOTLIGHT_DIRECTORY_UPDATE_FAILED,
  LINKED_EMAIL_ADDED,
  CHANGE_DEFAULT_EMAIL_SUCCESSFUL,
  RESEND_VERIFICATION_EMAIL_SUCCESSFUL,
  CANCEL_VERIFICATION_EMAIL_SUCCESSFUL,
  SET_PASSWORD_SAVED,
  SET_PASSWORD_FAILED,
  SKILLS_LOADED,
  HAS_ERROR,
}

const initialStateHandling: Partial<AuthorizedState> = {
  action: AuthorizedStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState = <AuthorizedState>{
  user: null,
  profile: null,
  spotlightDirectories: null,
  linkedEmails: [],
  skills: [],
  ...initialStateHandling,
};

const authorizedReducer = createReducer(
  initialState,

  on(AuthorizedActions.setUser, (state, { user }) => ({
    ...state,
    user,
  })),

  on(AuthorizedActions.updateUser, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    },
    action: AuthorizedStateAction.USER_SAVED,
  })),
  on(AuthorizedActions.updateUserFailure, (state, { errors }) => ({
    ...state,
    error: { ...errors },
    action: AuthorizedStateAction.SAVE_USER_FAILED,
  })),

  on(AuthorizedActions.loadProfile, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.loadProfileSuccess, (state, { profile }) => ({
    ...state,
    profile: <AccountsProfile>{
      ...profile,
      location: profile.location ? profile.location : <AccredibleProfileLocation>{},
      personalInfoIncomplete: _isPersonalInfoIncomplete(profile),
    },
    action: AuthorizedStateAction.PROFILE_LOADED,
  })),
  on(AuthorizedActions.loadProfileFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.updateProfile, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.updateProfileSuccess, (state, { profile }) => ({
    ...state,
    profile: {
      ...profile,
      personalInfoIncomplete: _isPersonalInfoIncomplete(profile),
    },
    user: { ...state.user, name: profile.name, avatar: profile.avatar_url },
    action: AuthorizedStateAction.PROFILE_SAVED,
  })),
  on(AuthorizedActions.updateProfileFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.loadSpotlightDirectories, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.loadSpotlightDirectoriesSuccess, (state, { spotlightDirectories }) => ({
    ...state,
    spotlightDirectories: _mapCustomAttributeUserValueToValue(spotlightDirectories),
    action: AuthorizedStateAction.SPOTLIGHT_DIRECTORIES_LOADED,
  })),
  on(AuthorizedActions.loadSpotlightDirectoriesFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.updateSpotlightDirectoryUserSetting, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(
    AuthorizedActions.updateSpotlightDirectoryUserSettingSuccess,
    (state, { spotlightDirectory }) => ({
      ...state,
      // Replace the old directory settings with the new ones
      spotlightDirectories: _overwriteOldSettingsForDirectoryWithUpdatedSettings(
        state.spotlightDirectories,
        // We need to map the custom_attributes user_value properties to the value property for the new updated spotlightDirectory
        _mapCustomAttributeUserValueToValue([spotlightDirectory])[0],
      ),
      action: AuthorizedStateAction.SPOTLIGHT_DIRECTORY_UPDATED,
    }),
  ),
  on(AuthorizedActions.updateSpotlightDirectoryUserSettingFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.loadLinkedEmails, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.loadLinkedEmailsSuccess, (state, { linkedEmails }) => ({
    ...state,
    user: {
      ...state.user,
      email: linkedEmails.find((email) => email.default === true).email,
    },
    linkedEmails,
  })),
  on(AuthorizedActions.loadLinkedEmailsFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.changeDefaultEmail, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.changeDefaultEmailSuccess, (state, { linkedEmails }) => ({
    ...state,
    user: {
      ...state.user,
      email: linkedEmails.find((email) => email.default === true).email,
    },
    linkedEmails,
    action: AuthorizedStateAction.CHANGE_DEFAULT_EMAIL_SUCCESSFUL,
  })),
  on(AuthorizedActions.changeDefaultEmailFailure, (state, _error) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.addLinkedEmail, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.addLinkedEmailSuccess, (state) => ({
    ...state,
    action: AuthorizedStateAction.LINKED_EMAIL_ADDED,
  })),
  on(AuthorizedActions.addLinkedEmailFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.resendEmailVerification, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.resendEmailVerificationSuccess, (state) => ({
    ...state,
    action: AuthorizedStateAction.RESEND_VERIFICATION_EMAIL_SUCCESSFUL,
  })),
  on(AuthorizedActions.resendEmailVerificationFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.cancelEmailVerification, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.cancelEmailVerificationSuccess, (state) => ({
    ...state,
    linkedEmails: state.linkedEmails.filter((email) => email.verified),
    action: AuthorizedStateAction.CANCEL_VERIFICATION_EMAIL_SUCCESSFUL,
  })),
  on(AuthorizedActions.cancelEmailVerificationFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  on(AuthorizedActions.setPassword, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.setPasswordSuccess, (state) => ({
    ...state,
    user: {
      ...state.user,
      has_password: true,
    },
    action: AuthorizedStateAction.SET_PASSWORD_SAVED,
  })),
  on(AuthorizedActions.setPasswordFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.SET_PASSWORD_FAILED,
  })),

  on(AuthorizedActions.loadSkills, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(AuthorizedActions.loadSkillsSuccess, (state, { skills }) => ({
    ...state,
    skills,
    action: AuthorizedStateAction.SKILLS_LOADED,
  })),
  on(AuthorizedActions.loadSkillsFailure, (state) => ({
    ...state,
    action: AuthorizedStateAction.HAS_ERROR,
  })),

  // RESET
  on(AuthorizedActions.resetValue, (state, { name }) => ({
    ...state,
    ...initialStateHandling,
    [name]: initialState[name],
  })),
  on(AuthorizedActions.resetState, () => initialState),
);

export function reducer(state: AuthorizedState, action: Action): AuthorizedState {
  return authorizedReducer(state, action);
}

const _overwriteOldSettingsForDirectoryWithUpdatedSettings = (
  currentSpotlightDirectories: AccountsSpotlightDirectory[],
  newSettingsForDirectory: AccountsSpotlightDirectory,
): AccountsSpotlightDirectory[] => {
  // Find the index of the directory to be updated in the array
  const indexToBeUpdated = currentSpotlightDirectories.findIndex((spotlightDirectory) => {
    return (
      spotlightDirectory.spotlight_directory_id === newSettingsForDirectory.spotlight_directory_id
    );
  });

  // Copy the existing spotlightDirectories array from the state
  const updatedSpotlightDirectories = [...currentSpotlightDirectories];
  // Replace the old settings object with the new one
  updatedSpotlightDirectories[indexToBeUpdated] = newSettingsForDirectory;

  // Return the updatedSpotlightDirectories array
  return updatedSpotlightDirectories;
};

/*
  Map the all the custom attributes for each of the spotlight directory, so they have a value property derived from the user_value.value property.
  This value property will be used in the custom-attribute-input component
 */
const _mapCustomAttributeUserValueToValue = (
  spotlightDirectories: AccountsSpotlightDirectory[],
): AccountsSpotlightDirectory[] => {
  return spotlightDirectories.map((spotlightDirectory) => {
    return {
      ...spotlightDirectory,
      custom_attributes: spotlightDirectory.custom_attributes.map((customAttribute) => {
        // Endpoint /v1/accounts/users/11548/spotlight_directory_user_setting returns the user_value.value property as an array with one number
        // for select_one custom attributes. Here we change it to just a number.
        if (
          customAttribute.type === CustomAttributeType.SELECT_ONE &&
          Array.isArray(customAttribute.user_value?.value)
        ) {
          return { ...customAttribute, value: customAttribute.user_value?.value[0] };
        } else {
          return { ...customAttribute, value: customAttribute.user_value?.value };
        }
      }),
    };
  });
};

/**
 * Gets from the profile object the fields that are related with personal information
 * Checks if some fields are not completed
 */
const _isPersonalInfoIncomplete = (profile: AccountsProfile): boolean => {
  const personalInfo = (({ avatar_url, location, user_work_experiences, bio, social_media }) => ({
    avatar_url,
    location,
    user_work_experiences,
    bio,
    twitter: social_media?.twitter ? social_media.twitter : '',
    linkedin: social_media?.linkedin ? social_media.linkedin : '',
  }))(profile);
  return Object.values(personalInfo).some((info: string) => info?.length === 0);
};
