import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Unauthenticated Routes (redirects to settings route when logged in)
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../containers/login/login.container.module').then((m) => m.LoginContainerModule),
  },
  {
    path: 'mfa-login',
    loadChildren: () =>
      import('../containers/mfa-login/mfa-login.container.module').then(
        (m) => m.MfaLoginContainerModule,
      ),
  },
  {
    path: 'mfa-reset-request',
    loadChildren: () =>
      import('../containers/mfa-reset-request/mfa-reset-request.container.module').then(
        (m) => m.MfaResetRequestContainerModule,
      ),
  },
  {
    path: 'reset-mfa',
    loadChildren: () =>
      import('../containers/reset-mfa/reset-mfa.module').then((m) => m.ResetMfaModule),
  },
  {
    path: 'locked-account',
    loadChildren: () =>
      import('../containers/locked-account/locked-account.container.module').then(
        (m) => m.LockedAccountContainerModule,
      ),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('../containers/sign-up/sign-up.container.module').then((m) => m.SignUpContainerModule),
  },
  {
    path: 'retrieve-credentials',
    loadChildren: () =>
      import('../containers/retrieve-credentials/retrieve-credentials.container.module').then(
        (m) => m.RetrieveCredentialsContainerModule,
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('../containers/forgot-password/forgot-password.container.module').then(
        (m) => m.ForgotPasswordContainerModule,
      ),
  },

  // Can access regardless of authentication status
  {
    path: 'reset_password',
    redirectTo: 'reset-password',
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('../containers/reset-password/reset-password.container.module').then(
        (m) => m.ResetPasswordContainerModule,
      ),
  },
  {
    path: 'email-verification',
    loadChildren: () =>
      import('../containers/email-verification/email-verification.container.module').then(
        (m) => m.EmailVerificationContainerModule,
      ),
  },
  {
    path: 'transcripts-terms-and-conditions',
    loadComponent: () =>
      import(
        '../containers/transcripts-terms-and-conditions/transcripts-terms-and-conditions.component'
      ).then((m) => m.TranscriptsTermsAndConditionsComponent),
  },
  {
    path: 'complete-your-profile',
    loadChildren: () =>
      import('@accounts/feature-complete-your-profile').then((m) => m.CompleteYourProfileRoutes),
  },

  // Authenticated routes
  {
    path: 'settings',
    loadChildren: () =>
      import('../containers/settings/settings.container.module').then(
        (m) => m.SettingsContainerModule,
      ),
  },
  {
    path: 'my-spotlight-directories',
    loadChildren: () =>
      import('@accounts/feature-my-spotlight-directories').then(
        (m) => m.MySpotlightDirectoriesRoutes,
      ),
  },
  // Invalid Routes
  {
    path: '404',
    loadChildren: () =>
      import('../containers/acs-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../containers/acs-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AccountsRoutingModule {}
